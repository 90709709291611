<template>
  <div class="position-point">
    <Navbar
      title="添加定位点"
      left-text="返回"
      right-text="保存"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="position m-t-10">
        <van-form class="h-100">
            <van-field
                v-model="param.name"
                label="定位点名称"
                name="name"
                placeholder="请输入定位点名称"
                :rules="[{ required: true, message: '请输入定位点名称' }]"
            />
            <van-field
                v-model="param.desc"
                label="定位点描述"
                name="desc"
                placeholder="请输入定位点描述"
            />
            <van-field
                label="定位点图片"
                name="imgUrl"
            >
            <template #input>
                <UploadPhoto v-model="param.imgUrl" capture="camera" />
            </template>
            </van-field>
            <van-field
                label="定位点坐标"
            >
            <template #input>
                
            </template>
            </van-field>
            <div class="point-map-wrap">
                <div ref="uuid" class="point-map"></div>
            </div>
        </van-form>
        
    </div>
  </div>
</template>

<script>
import { util } from '@/libs'
export default {
    data() {
        return {
            param: {
                // imgUrl: 'http://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png'
            },
            uuid: util.uuid,
            map: null,
            zoom: 11
        }
    },
    methods: {
        onClickLeft() {
            this.$router.back()
        },
        onClickRight() {
            console.log(this.param)
        },
        
        initMap() {
            this.map = new T.Map(this.$refs.uuid, {})
            this.map.centerAndZoom(new T.LngLat(113.619320,34.747790), 12);
            var lo = new T.Geolocation();
            const _this = this
            let fn = function (e) {
                Object.assign(_this.param, { lng: e.lnglat.lng, lat: e.lnglat.lat })
                // 当前为移动端
                if (this.getStatus() == 0){
                    map.centerAndZoom(e.lnglat, 15)
                    // alert("获取定位坐标："+e.lnglat.lat + "," + e.lnglat.lng)
                    var marker = new T.Marker(e.lnglat);
                    map.addOverLay(marker);

                }
                // 当前为PC端
                if(this.getStatus() == 1){
                    map.centerAndZoom(e.lnglat, e.level)
                    // alert("获取定位坐标："+e.lnglat.lat + "," + e.lnglat.lng)
                    var marker = new T.Marker(e.lnglat);
                    map.addOverLay(marker);
                }
            }
            // 定位当前位置
            lo.getCurrentPosition(fn);

        }
    },

    async mounted() {
        await util.initTmap()
        this.initMap()
    }
};
</script>

<style lang="less" >
.position-point {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .position {
        height: calc(100% - 50px);
        overflow-y: scroll;
        // background: #333;
        .point-map-wrap {
            box-sizing: border-box;
            padding-right: 10px;
            padding-left: 10px;
        }
        .point-map {
            height: 300px;
        }
    }
}
</style>
